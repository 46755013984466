<script>
import axios from 'axios';
import { Message } from 'element-ui';
export default {
    name: 'Logo',
    data() {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        login() {
            
            axios.post('/auth/login', {
                username: this.username,
                password: this.password
            }).then(res => {

                localStorage.setItem('token', res.token);

                window.location.href = '/';

                
            }).catch(err => {
                if(err.code == 60004){
                    Message({
                        type: 'error',
                        message: '用户名或密码错误'
                    });
                }
            });
        }
    }
}

</script>
<template>
<section class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="w-8 h-8 mr-2" src="../../assets/images/logo1.jpg" alt="logo">
          无人智能便利店    
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  登录
              </h1>
              <div class="space-y-4 md:space-y-6">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">用户名</label>
                      <input type="text" v-model="username" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="请输入用户名" required="">
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">密码</label>
                      <input type="password" v-model="password" name="password" id="password" @keyup.enter="login" placeholder="请输入密码" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                  </div>    
                  <!-- <div class="flex items-center justify-between">
                      <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="remember" class="text-gray-500 dark:text-gray-300">记住我</label>
                          </div>
                      </div>
                      <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                  </div> -->
                  <button type="submit" class="w-full text-white bg-[#2563eb] hover:bg-[#1d4ed8] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" style="background-color: #007bff;" @click="login">登 录</button>
                  <!-- <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      没有账号？ <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">注册</a>
                  </p> -->
                </div>
          </div>
      </div>
  </div>
</section>
</template>

<style scoped> 
</style>
